import React, { useMemo } from 'react';

import NavbarContainer from 'components/ui/navbar/NavbarContainer';
import MenuContainer from 'components/ui/menu/MenuContainer';
import BreadcrumbContainer from 'components/ui/breadcrumb/BreadcrumbContainer';
import ModalRoot from 'components/ModalRoot';
import { UiMsgContainer } from 'components/ui/UiMsg';
import EventNotification from 'components/ui/event-notification/EventNotification';
import { VIEW } from 'bng/routes';
import BngErrorBoundary from 'components/bng/ui/BngErrorBoundary';
import GlobalLoader from 'bng/common/GlobalLoader';
import useEventBus from 'components/hooks/useEventBus';
import Api from 'components/Api';
import useReduxDispatch from 'components/hooks/useReduxDispatch';
import { MENU } from 'components/ui/redux/Actions';
import AdaAiChatContainer from 'components/bng/adaAi/AdaAiChatContainer';
import { skipAds } from 'components/appStartupChecks';

export default function DefaultLayout({ view, children }) {
  const dispatch = useReduxDispatch();
  const showMenu = !view || view === VIEW.Discover;

  const showAds = useMemo(() => !skipAds(), []);

  useEventBus(Api.Project.FOLDER_CREATED_EVENT, () => {
    if (!showMenu) {
      return;
    }
    dispatch?.(MENU.findRoots());
  });

  return (
    <>
      {showAds && (
        <BngErrorBoundary>
          <EventNotification />
        </BngErrorBoundary>
      )}

      <BngErrorBoundary>
        <NavbarContainer view={view} />
      </BngErrorBoundary>

      {showMenu && (
        <>
          <BngErrorBoundary>
            <MenuContainer view={view} />
          </BngErrorBoundary>
          <BngErrorBoundary>
            <BreadcrumbContainer />
          </BngErrorBoundary>
        </>
      )}

      <div id="right-menu-container" className="hide"></div>
      <div id="floating-object-container" className="floating-object-actions on-react hide" />

      {children}

      <BngErrorBoundary>
        <ModalRoot />
      </BngErrorBoundary>

      <BngErrorBoundary>
        <AdaAiChatContainer />
      </BngErrorBoundary>

      <BngErrorBoundary>
        <UiMsgContainer />
      </BngErrorBoundary>

      <GlobalLoader />
    </>
  );
}
