import styles from './ProjectionDetailsDialog.module.css';

import { useMemo } from 'react';

import Dialog from 'components/ui/Dialog';
import useBimContext from 'components/hooks/useBimContext';
import useFetchData from 'components/hooks/useFetchData';
import Api from 'components/Api';
import BngTable from 'components/bng/ui/BngTable';
import BngTag from 'components/bng/ui/BngTag';
import Utils from 'components/Utils';
import { proposalUtils } from 'components/service/bng/AccountApi';

const buildProjectionItemsColumns = (context, currencySymbol) => {
  return [
    {
      key: 'description',
      label: context.msg.t('description'),
      sortable: true,
      render: (row) => {
        const planKey = row.proposalPricing?.pricing.planKey;
        let name = '';
        switch (row.type) {
          case 'MANUAL':
            name = row.description;
            break;
          case 'VOUCHER':
            if (row.props.voucherType && row.props.voucherValue) {
              name = `${context.msg.t('voucher.' + row.props.voucherType)}: ${
                row.props.voucherType === 'BONUS' ? '' : row.props.voucherValue
              } ${proposalUtils.translationsForProposalPricing(row.proposalPricing).name}`;
            } else {
              name = `${context.msg.t('voucher')}: ${row.description}`;
            }
            break;
          case 'UPFRONT':
            name = `${context.msg.t('upfront')}: ${row.description}`;
            break;
          default:
            name = proposalUtils.translationsForProposalPricing(row.proposalPricing).name;
        }

        return <div>{`${name} ${context.msg.translateIfHasKey(`${planKey}.card.title`) ?? ''}`}</div>;
      },
    },
    {
      key: 'status',
      label: context.msg.t('status'),
      sortable: true,
      render: (row) => {
        const isPaid = row.paid;
        const className = isPaid ? styles.paidTag : styles.openTag;
        return (
          <div>
            <BngTag icon={'paid'} iconProps={{ outlined: !isPaid }} className={className}>
              <span className={styles.itemStatus}>{context.msg.t(`situation.${isPaid ? 'paid' : 'open'}`)}</span>
            </BngTag>
          </div>
        );
      },
    },
    {
      key: 'quantity',
      label: context.msg.t('quantity'),
      render: (row) => {
        return <div>{row.proposalPricing?.quantity || 1}</div>;
      },
    },
    {
      key: 'pricing',
      label: context.msg.t('price'),
      sortable: true,
      size: 100,
      render: (row) => {
        const pricing = proposalUtils.formatPricing(row.pricing, currencySymbol);
        return <div className={styles.itemPricing}>{pricing}</div>;
      },
    },
  ];
};

function BuyerInfo({ invoiceProjection, contractDate }) {
  const context = useBimContext();

  const getProjectionDate = () => {
    const startingDate =
      invoiceProjection.type === 'PARTIAL'
        ? contractDate
        : new Date(contractDate.getFullYear(), contractDate.getMonth(), 1);
    const lastDayOfMonth = new Date(contractDate.getFullYear(), contractDate.getMonth() + 1, 0);

    return `${Utils.Date.formatDate(startingDate)} -> ${Utils.Date.formatDate(lastDayOfMonth)}`;
  };

  return (
    <div className={styles.buyerInfo}>
      <div className={styles.buyerTitle}>{context.msg.t('buyer.info')}</div>

      <div className={styles.buyerInfoRow}>
        <div className={styles.infoItem}>
          <strong>{context.msg.t('account.id')}:</strong>
          <div>{invoiceProjection.payer.bimAccountId}</div>
        </div>
        <div className={styles.infoItem}>
          <strong>{context.msg.t('reference.date')}:</strong>
          <div>{Utils.Date.formatDate(new Date(`${invoiceProjection.referenceMonth}T12:00:00`))}</div>
        </div>

        <div className={styles.infoItem}>
          <strong>{context.msg.t('company')}:</strong>
          <div>{invoiceProjection.payer.companyName}</div>
        </div>
        <div className={styles.infoItem}>
          <strong>{context.msg.t('full_name')}:</strong>
          <div>{invoiceProjection.payer.fullName}</div>
        </div>

        <div className={styles.infoItem}>
          <strong>{context.msg.t('email')}:</strong>
          <div>{invoiceProjection.payer.emails}</div>
        </div>
        <div className={styles.infoItem}>
          <strong>{context.msg.t('phone')}:</strong>
          <div>{invoiceProjection.payer.phone || context.msg.t('not.informed')}</div>
        </div>

        <div className={styles.infoItem}>
          <strong>{context.msg.t('PERIOD')}:</strong>
          <div>{getProjectionDate()}</div>
        </div>
      </div>
    </div>
  );
}

export default function ProjectionDetailsDialog({ closeModal = _.noop, accountId, invoiceProjectionId }) {
  const context = useBimContext();
  const $projection = useFetchData(async () => {
    return await Api.Account.findInvoiceProjectionInfo(accountId, invoiceProjectionId);
  });
  const contract = $projection.data?.invoiceProjection.contract;
  const currencySymbol = contract?.currency.symbol;
  const cols = useMemo(() => buildProjectionItemsColumns(context, currencySymbol), [currencySymbol]);

  return (
    <Dialog
      className={`ProjectionDetailsDialog large ${styles.ProjectionDetailsDialog}`}
      onClose={closeModal}
      loading={$projection.isLoading}
      title={context.msg.t('billing.details')}
      newDialogLayout
    >
      <Dialog.Body className={styles.dialogBody}>
        {$projection.data && (
          <>
            <BuyerInfo
              invoiceProjection={$projection.data.invoiceProjection}
              contractDate={
                $projection.data.invoiceProjection.type === 'PARTIAL'
                  ? new Date(`${$projection.data?.invoiceProjectionItems[0].partialPaymentDate}T12:00:00`)
                  : new Date(`${$projection.data.invoiceProjection.referenceMonth}T12:00:00`)
              }
            />
            <div className={styles.buyerTitle}>{context.msg.t('billing.items')}</div>
            <div className={styles.projectionItemsTableWrapper}>
              <BngTable rows={$projection.data.invoiceProjectionItems} cols={cols} stickyHeader />
            </div>
            <div className={styles.totalPricingWrapper}>
              <span className={styles.totalPricingLabel}>{`${context.msg.t('total')}:`}</span>
              <span className={styles.totalPricing}>
                {proposalUtils.formatPricing($projection.data.invoiceProjection.nominalPricing, currencySymbol)}
              </span>
            </div>
          </>
        )}
      </Dialog.Body>
    </Dialog>
  );
}
