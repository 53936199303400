import styles from './Checkout.module.css';

import React, { useEffect, useMemo, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { animated, useSpring } from '@react-spring/web';

import useTranslation from 'components/hooks/useTranslation';
import Dialog from 'components/ui/Dialog';
import BngIconButton from 'components/bng/ui/BngIconButton';
import BngNumberCounter from 'components/bng/form/BngNumberCounter';
import BngField from 'components/bng/form/BngField';
import { proposalUtils } from 'components/service/bng/AccountApi';
import { BngCheckboxPure } from 'components/bng/form/BngCheckbox';
import BngButton from 'components/bng/ui/BngButton';
import Icon from 'components/ui/common/Icon';
import { Axios } from 'commonUtils';
import useBimContext from 'components/hooks/useBimContext';
import BngEmpty from 'components/bng/ui/BngEmpty';
import useBimNavigate from 'components/hooks/useBimNavigate';
import UiMsg from 'components/ui/UiMsg';
import UiBlocker from 'components/bng/ui/UiBlocker';
import Utils from 'components/Utils';
import Api from 'components/Api';
import { getAppliedItemFromVoucher, getTotalVoucherPricing } from 'components/service/bng/MarketplaceApi';
import { calculateAccountConsumption } from 'components/ui/accounts/tabs/AccFinanceTab';
import GroupKeys from 'components/bng/pages/marketplace/tabs/GroupKeys';
import { useUserCanActivateWithoutRequest } from 'components/bng/pages/marketplace/MarketplacePage';

export function CheckoutDialog({
  closeModal = _.noop,
  items = [],
  account,
  afterSubmit = _.noop,
  currentPlan,
  isMasterOfCurrentAccount = false,
}) {
  const { t } = useTranslation();
  const [checkoutItems, setCheckoutItems] = useState(items);

  const onSubmit = async () => {
    await afterSubmit();
    closeModal();
  };

  return (
    <Dialog
      className={`CheckoutDialog ${styles.CheckoutDialog}`}
      onClose={closeModal}
      title={t('checkout')}
      newDialogLayout={true}
    >
      <Dialog.Body className={`checkoutBody ${styles.checkoutBody}`}>
        <Checkout
          items={checkoutItems}
          account={account}
          afterSubmit={onSubmit}
          currentPlan={currentPlan}
          isMasterOfCurrentAccount={isMasterOfCurrentAccount}
          updateSelectedItems={setCheckoutItems}
        />
      </Dialog.Body>
    </Dialog>
  );
}

export function CheckoutPage({ account, marketplaceItems, isMasterOfCurrentAccount = false, reloadMarketplaceItems }) {
  const { t } = useTranslation();
  const navigate = useBimNavigate();
  const { selectedItems, selectCard, updateSelectedItems, onClearSelection } = useOutletContext();

  const backAction = () => {
    navigate(-2);
  };

  return (
    <div className={`CheckoutPage ${styles.CheckoutPage}`}>
      <BngIconButton
        icon={'arrow_back'}
        text={t('back_button')}
        onClick={backAction}
        className={styles.checkoutBackButton}
      />
      <h3>{t('order.summary')}</h3>
      <div className={styles.checkoutWrapper}>
        <Checkout
          items={selectedItems}
          selectCard={selectCard}
          updateSelectedItems={updateSelectedItems}
          marketplaceItems={marketplaceItems}
          account={account}
          currentPlan={marketplaceItems.activePlanForAccount}
          afterSubmit={async () => {
            backAction();
            onClearSelection();
            await reloadMarketplaceItems();
          }}
          isMasterOfCurrentAccount={isMasterOfCurrentAccount}
        />
      </div>
    </div>
  );
}

function HoverableIcon({ icon = '', iconOnHover = '' }) {
  const [hovering, setHovering] = useState(false);
  return (
    <Icon
      icon={hovering ? iconOnHover : icon}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    />
  );
}

export default function Checkout({
  items,
  selectCard = _.noop,
  marketplaceItems,
  isMasterOfCurrentAccount,
  updateSelectedItems = _.noop,
  account,
  afterSubmit = _.noop,
  currentPlan,
}) {
  const context = useBimContext();
  const { t } = useTranslation();
  const isConsultant = Utils.Users.isConsultant(context.user);

  const [agreed, setAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [voucherExpanded, setVoucherExpanded] = useState(false);
  const [voucherName, setVoucherName] = useState('');
  const [requestMessage, setRequestMessage] = useState('');
  const [voucher, setVoucher] = useState();

  const { activateWithoutRequest, activateWithoutRequestLoading } = useUserCanActivateWithoutRequest(account, isMasterOfCurrentAccount);
  const isViewUser = !isConsultant && context.permissions.isViewer() && !activateWithoutRequest;

  const voucherStyles = useSpring({
    height: voucherExpanded ? 65 : 20,
    overflow: 'hidden',
  });

  const tryToApplyVoucher = async () => {
    if (!voucherName || voucherName.length === 0 || items.length === 0) {
      return;
    }

    setLoading(true);
    try {
      let voucherToApply = voucher;
      if (!voucherToApply) {
        voucherToApply = await Api.Marketplace.fetchVoucher(voucherName, account.id);
      }

      if (!voucherToApply.publicVoucher && !isConsultant) {
        UiMsg.warn(t('unable.to.apply.voucher'));
        return;
      }

      const calculateAppliedVoucher = (voucher, items) => {
        let itemsApplied = [];
        items.forEach((item) => {
          let pricingType = null;
          switch (item.groupKey) {
            case GroupKeys.PLANS: {
              pricingType = proposalUtils.PRICING_TYPES.PLAN;
              break;
            }
            case GroupKeys.SERVICES: {
              pricingType = proposalUtils.PRICING_TYPES.SERVICE;
              break;
            }
            case GroupKeys.TEMPLATES: {
              pricingType = proposalUtils.PRICING_TYPES.SERVICE;
              break;
            }
            default: {
              pricingType = proposalUtils.PRICING_TYPES.ADDITIONAL;
            }
          }
          itemsApplied.push(
            ...getAppliedItemFromVoucher(voucher, pricingType, {
              calculatedPricing: item.pricing.pricing * item.quantity,
              pricingId: item.pricing.id,
              name: item.name,
            })
          );
        });
        return itemsApplied.sort((a, b) => {
          if (a.type === 'BONUS') {
            return -1;
          } else if (b.type === 'BONUS') {
            return 1;
          }
          return 0;
        });
      };

      const itemsApplied = calculateAppliedVoucher(voucherToApply, items);
      if (Object.keys(itemsApplied).length === 0) {
        UiMsg.warn(t('unable.to.apply.voucher'));
        return;
      }
      voucherToApply.itemsApplied = itemsApplied;

      setVoucher({ ...voucherToApply });
    } catch (e) {
      console.error('Error on function tryToApplyVoucher()', e);
      if (e.response?.status === 404) {
        UiMsg.warn(t('voucher.not.found'));
      } else {
        UiMsg.warn(t('unable.to.apply.voucher'));
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    tryToApplyVoucher();
  }, [items]);

  const submitProposal = async (values) => {
    setLoading(true);
    try {
      const plan = values.items.find((item) => item.groupKey === GroupKeys.PLANS);
      if (plan) {
        plan.billingPricingId = plan.pricing.id;
        plan.agreedPricing = plan.pricing.pricing;
      }

      const templateItems = values.items.filter((item) => item.groupKey === GroupKeys.TEMPLATES);
      if (templateItems.length > 0) {
        const accountInfo = await Api.Account.fetchBillingAccountInfo(context.accountId);
        const metrics = calculateAccountConsumption(accountInfo);
        const templatesUsage = templateItems.reduce((acc, template) => {
          return acc + template.templateStructures;
        }, 0);

        const structuresAdditionalAdded = values.items.find((item) => item.key === 'DEFAULT_STRUCTURE');
        const additionalQuantity = structuresAdditionalAdded?.quantity || 0;
        const additionalValue = structuresAdditionalAdded?.featureValue.value || 0;
        const futureUsage = metrics.usedStructures + templatesUsage;

        if (futureUsage > metrics.structuresSlots + additionalQuantity * additionalValue) {
          const structuresNeeded = futureUsage - metrics.structuresSlots;
          if (!structuresAdditionalAdded) {
            const structuresAdditionalToAdd = marketplaceItems.additionals.find(
              (item) => item.key === 'DEFAULT_STRUCTURE'
            );
            const structuresPackagesNeeded = Math.ceil(structuresNeeded / structuresAdditionalToAdd.featureValue.value);
            selectCard({
              cardItem: structuresAdditionalToAdd,
              groupKey: null,
              quantity: structuresPackagesNeeded,
              clearAll: false,
              pricingId: structuresAdditionalToAdd.pricings[0].id,
              canSelectQuantity: true,
            });
          } else {
            structuresAdditionalAdded.quantity = Math.ceil(
              structuresNeeded / structuresAdditionalAdded.featureValue.value
            );
          }
          setAgreed(false);
          UiMsg.warn(t('buy.templates.metrics.limit.error'));
          return;
        }
      }

      await Api.Marketplace.activateMarketplaceItem({
        items: values.items.filter((item) => item !== plan),
        plan,
        voucher,
        accountId: account.id,
        projectId: context.project.id,
        requestMessage,
      });

      UiMsg.ok(
        t(activateWithoutRequest ? 'marketplace.item.enabled.successfully' : 'marketplace.item.requested.successfully')
      );

      if (items.some((item) => item.openTicket)) {
        try {
          const title = `Adicional contratado através do Marketplace que necessita de Suporte`;
          await Api.Support.newTicketForMarketplace({
            title,
            items: items.filter((item) => item.openTicket),
            user: context.user,
            project: context.project,
            account,
          });

          UiMsg.ok(t('marketplace.item.ticket.opened'));
        } catch (e) {
          console.error('Error opening ticket in function submitProposal()', e);
          UiMsg.error(t('marketplace.item.ticket.open.error'));
        }
      }

      afterSubmit();
    } catch (e) {
      console.error('Error on function submitProposal()', e);
      UiMsg.error(t('marketplace.request.error'));
    } finally {
      setLoading(false);
    }
  };

  const commercialPhone = context.commercialSupportPhone;

  const calculateSubTotal = (formatted = true) => {
    let subtotalValue = items.reduce((currentValue, item) => currentValue + item.pricing.pricing * item.quantity, 0);
    return formatted ? proposalUtils.formatPricing(subtotalValue, items[0].pricing.currency.symbol) : subtotalValue;
  };

  const calculateTotal = () => {
    const subtotal = calculateSubTotal(false);
    const voucherValue = getTotalVoucherPricing(voucher?.itemsApplied);
    const totalPricing = Math.max(subtotal + voucherValue, 0);
    return proposalUtils.formatPricing(totalPricing, items[0].pricing.currency.symbol);
  };

  const removeCheckoutItem = (item) => {
    const updatedArray = items.filter((i) => i.id !== item.id);
    updateSelectedItems(updatedArray);
  };

  const emptyCheckout = items.length === 0;

  return (
    <UiBlocker block={loading || activateWithoutRequestLoading}>
      <div className={`Checkout ${styles.Checkout}`}>
        <BngEmpty
          isEmpty={emptyCheckout}
          className={styles.emptyCheckout}
          title={t('marketplace.checkout.no.items')}
          message={null}
        >
          <div className={styles.checkoutField}>
            <label className={styles.paymentFormat}>{t('billing_type_checkout')}</label>
          </div>
          <hr className={styles.divider} />
          {items.map((item, idx) => {
            const isPlan = item.groupKey === GroupKeys.PLANS;
            return (
              <React.Fragment key={`${idx}-${item.id}-${item.name}`}>
                {isPlan && currentPlan && (
                  <div className={`${styles.checkoutField} ${styles.itemField}`}>
                    <div className={`${styles.itemNameWrapper} ${styles.itemDetail}`}>
                      <div>
                        {proposalUtils.translationsForSales(currentPlan).name}
                        <strong>({t('current')})</strong>
                      </div>
                    </div>
                    <strong className={`${styles.itemPricing} ${styles.itemDetail}`}>
                      {proposalUtils.getPricingLabel(proposalUtils.getDefaultPricing(currentPlan.pricings))}
                    </strong>
                  </div>
                )}
                <div className={`${styles.checkoutField} ${styles.itemField}`}>
                  <div className={styles.itemNameWrapper}>
                    <div>{`${item.name} ${context.msg.translateIfHasKey(`${item.pricing.planKey}.card.title`) ?? ''
                      }`}</div>
                    {item.featureValue?.value > 1 && (
                      <div className={styles.smallPrint}>{`${item.pricing.currency.symbol} ${item.pricing.pricing / item.featureValue.value
                        } x ${item.featureValue.value}`}</div>
                    )}
                  </div>
                  {!isPlan && (
                    <BngField
                      label={null}
                      value={item.quantity}
                      inputComponent={BngNumberCounter}
                      blockRightBtn={!item.canSelectQuantity}
                      onChange={(e) => {
                        items[idx].quantity = e.target.value;
                        updateSelectedItems([...items]);
                      }}
                      onDelete={() => removeCheckoutItem(item)}
                    />
                  )}
                  {!isViewUser && (
                    <strong className={styles.itemPricing}>{proposalUtils.getPricingLabel(item.pricing)}</strong>
                  )}
                </div>
              </React.Fragment>
            );
          })}
          <hr className={styles.divider} />
          {!isViewUser && (
            <div className={styles.checkoutField}>
              <strong>{t('subtotal')}</strong>
              <strong>{!emptyCheckout && calculateSubTotal()}</strong>
            </div>
          )}
          {!voucher && (
            <animated.div className={styles.voucherWrapper} style={voucherStyles}>
              <div className={styles.expandVoucherButton} onClick={() => setVoucherExpanded(!voucherExpanded)}>
                <Icon icon={'payments'}></Icon>
                <span>{t('insert.voucher')}</span>
                <Icon icon={voucherExpanded ? 'expand_less' : 'expand_more'}></Icon>
              </div>
              <div className={styles.voucherFieldWrapper}>
                <BngField
                  placeholder={t('enter.voucher.here')}
                  withLabel={false}
                  value={voucherName}
                  onChange={(e) => {
                    setVoucherName(e.target.value);
                  }}
                  onKeyDown={async (e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      await tryToApplyVoucher();
                    }
                  }}
                />
                <BngIconButton icon={'arrow_forward'} onClick={tryToApplyVoucher} />
              </div>
            </animated.div>
          )}
          {voucher?.itemsApplied?.length > 0 &&
            items.length > 0 &&
            voucher.itemsApplied?.map(
              ({ name, pricing, type, duration, currency, discountValue, discountType }, idx) => {
                const voucherPricing =
                  type === 'BONUS'
                    ? t('bonified.for', [t(duration)])
                    : proposalUtils.getPricingLabel({
                      pricing,
                      currency: currency ?? items[0]?.pricing.currency,
                    });

                const isPercent = type === 'DISCOUNT' && discountType === 'PERCENT';
                return (
                  <React.Fragment key={`${idx}-${voucher.name}-${name}`}>
                    <div className={`${styles.checkoutField} ${styles.itemField}`}>
                      <div className={styles.voucherNameWrapper}>
                        <div>{`${voucher.name} (${isPercent ? `${discountValue}% ` : ''}${name})`}</div>
                      </div>
                      <div
                        className={`${styles.removeButton} ${styles.removeVoucherIcon}`}
                        onClick={() => {
                          setVoucher(null);
                        }}
                      >
                        <HoverableIcon icon={'check_circle'} iconOnHover={'cancel'} />
                      </div>
                      <strong className={styles.itemPricing}>{voucherPricing}</strong>
                    </div>
                  </React.Fragment>
                );
              }
            )}
          <hr className={styles.divider} />
          {!isViewUser && (
            <div className={styles.checkoutField}>
              <strong>{t('total')}</strong>
              <strong>{!emptyCheckout && calculateTotal()}</strong>
            </div>
          )}
          {!activateWithoutRequest && (
            <BngField
              placeholder={t('proposal.message')}
              rootClassName={styles.proposalMessageField}
              withLabel={false}
              value={requestMessage}
              onChange={(e) => {
                setRequestMessage(e.target.value);
              }}
            />
          )}
          {!isViewUser && (
            <BngCheckboxPure label={t('checkout.agree')} checked={agreed} onChange={() => setAgreed(!agreed)} />
          )}
          <BngButton onClick={() => submitProposal({ items })} disabled={!agreed} className={styles.finishButton}>
            {activateWithoutRequest
              ? t('checkout.finish')
              : isConsultant && !account.isManagedByBim
                ? t('checkout.send.proposal')
                : t('checkout.send.request')}
          </BngButton>
          {commercialPhone && (
            <>
              <hr className={styles.divider} />
              <div className={styles.supportFooter}>
                <Icon icon={'support_agent'} />
                <span>{t('questions')}</span>
                <a
                  href={Axios.getUri({
                    url: 'https://api.whatsapp.com/send',
                    params: { phone: commercialPhone, type: 'phone_number', app_absent: '0' },
                  })}
                  target={'_blank'}
                >
                  {t('checkout.contactCommercial')}
                </a>
              </div>
            </>
          )}
        </BngEmpty>
      </div>
    </UiBlocker>
  );
}
